import React, { useState, useContext, useEffect } from 'react';
import { DropdownWithIcon, TextInputWithIcon, DateInputWithIcon } from '../../Components/InputFields';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion, AnimatePresence } from 'framer-motion';
import LineItemTable from './LineItemTable';
import Modal from '../../Components/Modal';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Box from '@mui/material/Box';

const QuoteForm = ({ 
        billingprofiles, 
        customers, 
        onQuoteCreated, 
        selectedBillingProfileId, 
        onCustomerChange, 
        isFormExpanded, 
        setIsFormExpanded,
        onClientCreated
}) => {
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
    const [quoteData, setQuoteData] = useState({
        billingprofile: selectedBillingProfileId,
        lineItems: [],
        quoteDate: '',
        expirationDate: '',
        quoteStatus: 'Brouillon',
        customer: '',
    });

    useEffect(() => {
        setQuoteData((prevData) => ({
            ...prevData,
            billingprofile: selectedBillingProfileId,
        }));
    }, [selectedBillingProfileId]);

    const handleLineItemChange = (lineItems) => {
        setQuoteData(prevData => ({
            ...prevData,
            lineItems
        }));
    };

    const handleSubmit = async () => {
        const formattedQuoteData = {
            ...quoteData,
            lineItems: quoteData.lineItems.map(item => ({
                ...item,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price),
                tax: parseFloat(item.tax)
            }))
        };

        console.log('Formatted Quote Data:', formattedQuoteData);
        
        const result = await sendRequestWithToast({
            apiPath: 'devis/create',
            type: 'POST',
            request: formattedQuoteData,
            successMessage: "Devis créé avec succès!",
            errorMessage: "Erreur lors de la création du devis",
        });

        if (result && result.success) {
            onQuoteCreated();
        }
    };

    const handleInputChange = (name, value) => {
        setQuoteData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'customer') {
            console.log('Client sélectionné:', value);
            onCustomerChange(value); // Émettre l'ID du client sélectionné vers AccountingDashboard
        }
    };

    const quoteStatusOptions = [
        { label: 'Brouillon', value: 'Brouillon' },
        { label: 'Envoyé', value: 'Envoyé' },
        { label: 'Accepté', value: 'Accepté' },
        { label: 'Refusé', value: 'Refusé' },
        { label: 'Expiré', value: 'Expiré' },
    ];

    return (
        <Card variant="level1">
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" color="blue-gray">
                Nouveau Devis
              </Typography>
              <IconButton onClick={() => setIsFormExpanded(!isFormExpanded)}>
                <motion.div
                    animate={{ rotate: isFormExpanded ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <ExpandMoreIcon />
                </motion.div>
              </IconButton>
            </Box>
            <AnimatePresence>
              {isFormExpanded && (
                <motion.div
                  key="quoteFormContent"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  style={{ overflow: 'hidden' }}
                >
                    <>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DropdownWithIcon
                                    label="Client"
                                    iconName="account-circle"
                                    value={quoteData.customer}
                                    onChange={(e) => handleInputChange('customer', e.target.value)}
                                    options={[{ label: 'Sélectionnez un client', value: '' }, ...customers]}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DateInputWithIcon
                                    label="Date du Devis"
                                    value={quoteData.quoteDate}
                                    onChange={(e) => handleInputChange('quoteDate', e.target.value)}
                                />
                            </div>
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DateInputWithIcon
                                    label="Date d'Expiration"
                                    value={quoteData.expirationDate}
                                    onChange={(e) => handleInputChange('expirationDate', e.target.value)}
                                />
                            </div>
                        </div>

                        <LineItemTable
                            lineItems={quoteData.lineItems}
                            onLineItemChange={handleLineItemChange}
                        />
                        <div className="w-full px-3 mb-6">
                            <DropdownWithIcon
                                label="Statut du Devis"
                                iconName="list"
                                value={quoteData.quoteStatus}
                                onChange={(e) => handleInputChange('quoteStatus', e.target.value)}
                                options={quoteStatusOptions}
                            />
                        </div>
                        <div className="px-3">
                            <Button
                                color='primary'
                                variant="contained"
                                ripple="true"
                                onClick={handleSubmit}
                            >
                                Créer le Devis
                            </Button>
                        </div>
                    </>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
    );
};

export default QuoteForm;
