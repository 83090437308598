import React, { useState, useContext, useEffect } from 'react';
import { DropdownWithIcon, TextInputWithIcon, DateInputWithIcon } from '../../Components/InputFields';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import LineItemTable from './LineItemTable';
import Modal from '../../Components/Modal';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Checkbox from '@mui/material/Checkbox';
import { motion, AnimatePresence } from 'framer-motion';


const InvoiceForm = ({ 
        billingprofiles, 
        customers, 
        onInvoiceCreated, 
        selectedBillingProfileId, 
        onCustomerChange, 
        isFormExpanded, 
        setIsFormExpanded,
        onClientCreated
}) => {
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
    const [invoiceData, setInvoiceData] = useState({
        billingprofile: selectedBillingProfileId,
        lineItems: [],
        invoiceDate: '',
        dueDate: '',
        invoiceStatus: 'Brouillon',
        customer: '',
    });

    useEffect(() => {
        setInvoiceData((prevData) => ({
            ...prevData,
            billingprofile: selectedBillingProfileId,
        }));
    }, [selectedBillingProfileId]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false); // Modal for creating new client
    const [clientTypeMetadata, setClientTypeMetadata] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [paidReceipts, setPaidReceipts] = useState([]);
    const [selectedReceipts, setSelectedReceipts] = useState([]);

    useEffect(() => {
        const fetchClientMetadata = async () => {
            try {
                const clientMetadataResponse = await sendRequest({
                    apiPath: `crm/types/read/client`,
                    type: 'GET',
                });
                console.log("Métadonnées client récupérées:", clientMetadataResponse);
                setClientTypeMetadata(clientMetadataResponse);
            } catch (err) {
                console.error("Erreur lors de la récupération des métadonnées client:", err);
            }
        };

        fetchClientMetadata();
    }, [sendRequest]);

    const handleLineItemChange = (lineItems) => {
        setInvoiceData(prevData => ({
            ...prevData,
            lineItems
        }));
    };

    const handleSubmit = async () => {
        const formattedInvoiceData = {
            ...invoiceData,
            lineItems: invoiceData.lineItems.map(item => ({
                ...item,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price),
                tax: parseFloat(item.tax)
            }))
        };

        console.log('Formatted Invoice Data:', formattedInvoiceData);
        
        const result = await sendRequestWithToast({
            apiPath: 'facture/create',
            type: 'POST',
            request: formattedInvoiceData,
            successMessage: "Facture créée avec succès!",
            errorMessage: "Erreur lors de la création de la facture",
        });

        if (result && result.success) {
            onInvoiceCreated();
        }
    };

    const handleInputChange = (name, value) => {
        setInvoiceData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'customer') {
            console.log('Client sélectionné:', value);
            onCustomerChange(value); // Émettre l'ID du client sélectionné vers AccountingDashboard
        }
    };

    const invoiceStatusOptions = [
        { label: 'Brouillon', value: 'Brouillon' },
        { label: 'Envoyée', value: 'Envoyée' },
        { label: 'Premier rappel', value: 'Premier rappel' },
        { label: 'Deuxième Rappel', value: 'Deuxième Rappel' },
        { label: 'Troisième Rappel', value: 'Troisième Rappel' },
        { label: 'Payée', value: 'Payée' },
    ];

    const handleFormInputChange = (name, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleOpenModal = () => {
        fetchPaidReceipts();
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const fetchPaidReceipts = async () => {
        try {
            const response = await sendRequest({
                apiPath: 'recudecaisse/paid',
                type: 'GET',
            });
            setPaidReceipts(response.receipts);
        } catch (err) {
            console.error("Erreur lors de la récupération des reçus payés:", err);
        }
    };

    const handleReceiptSelection = (receiptId) => {
        setSelectedReceipts((prevSelected) =>
            prevSelected.includes(receiptId)
                ? prevSelected.filter((id) => id !== receiptId)
                : [...prevSelected, receiptId]
        );
    };

    const createInvoiceFromReceipts = async () => {
        const result = await sendRequestWithToast({
            apiPath: 'facture/create_from_receipts',
            type: 'POST',
            request: {
                receiptIds: selectedReceipts,
            },
            successMessage: "Facture créée avec succès à partir des reçus!",
            errorMessage: "Erreur lors de la création de la facture à partir des reçus",
        });

        if (result && result.success) {
            onInvoiceCreated();
            setIsModalOpen(false);
            setSelectedReceipts([]);
        }
    };

    // Handle modal for creating new client
    const handleOpenNewClientModal = () => {
        setIsNewClientModalOpen(true);
    };

    const handleCloseNewClientModal = () => {
        setIsNewClientModalOpen(false);
        setFormValues({});
    };

    const handleCreateClient = async () => {
        const formData = new FormData();
        Object.keys(formValues).forEach(key => {
            if (formValues[key] instanceof File) {
                formData.append(key, formValues[key]);
            } else {
                formData.append(key, formValues[key]);
            }
        });

        try {
            const result = await sendRequestWithToast({
                apiPath: `/crm/instances/client/create`,
                type: 'POST',
                request: formData,
                successMessage: "Client créé avec succès",
                errorMessage: "Erreur lors de la création du client",
            });
            
            if (result) {
                handleCloseNewClientModal();
                await onClientCreated(); // Call the fetchCustomers function after successful creation
            }
        } catch (err) {
            console.error("Erreur lors de la création du client:", err);
        }
    };

    const renderInputField = (key, property) => {
        if (property.type === 'label') {
            const dropdownOptions = property.accepted_values.map(value => ({
                label: value,
                value: value
            }));

            return (
                <DropdownWithIcon
                    key={key}
                    id={key}
                    label={property.display_name}
                    iconName="label"
                    value={formValues[key] || property.accepted_values[0]}
                    onChange={(e) => handleFormInputChange(key, e.target.value)}
                    options={dropdownOptions}
                />
            );
        } else {
            return (
                <TextInputWithIcon
                    key={key}
                    id={key}
                    type="text"
                    placeholder={property.value}
                    label={property.display_name}
                    iconName="text"
                    value={formValues[key] || ''}
                    onChange={(e) => handleFormInputChange(key, e.target.value)}
                    name={key}
                />
            );
        }
    };

    return (
        <Card variant="level1">
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" color="blue-gray">
                Nouvelle Facture
              </Typography><IconButton onClick={() => setIsFormExpanded(!isFormExpanded)}>
  <motion.div
    animate={{ rotate: isFormExpanded ? 180 : 0 }}
    transition={{ duration: 0.3 }}
  >
    <ExpandMoreIcon />
  </motion.div>
</IconButton>
            </Box>
            <AnimatePresence>
              {isFormExpanded && (
                <motion.div
                  key="invoiceFormContent"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  style={{ overflow: 'hidden' }}
                >
                    <>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DropdownWithIcon
                                    label="Client"
                                    iconName="account-circle"
                                    value={invoiceData.customer}
                                    onChange={(e) => handleInputChange('customer', e.target.value)}
                                    options={[{ label: 'Sélectionnez un client', value: '' }, ...customers]}
                                />
                            </div>
                            <div className="px-3 w-full md:w-1/3 flex justify-end items-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    ripple="true"
                                    onClick={handleOpenModal}
                                >
                                    A partir de reçus
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    ripple="true"
                                    style={{ marginLeft: '10px' }}
                                    onClick={handleOpenNewClientModal}
                                >
                                    Nouveau client
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DateInputWithIcon
                                    label="Date de Facturation"
                                    value={invoiceData.invoiceDate}
                                    onChange={(e) => handleInputChange('invoiceDate', e.target.value)}
                                />
                            </div>
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DateInputWithIcon
                                    label="Date d'Échéance"
                                    value={invoiceData.dueDate}
                                    onChange={(e) => handleInputChange('dueDate', e.target.value)}
                                />
                            </div>
                        </div>

                        <LineItemTable
                            lineItems={invoiceData.lineItems}
                            onLineItemChange={handleLineItemChange}
                        />
                        <div className="w-full px-3 mb-6">
                            <DropdownWithIcon
                                label="Statut de la Facture"
                                iconName="list"
                                value={invoiceData.invoiceStatus}
                                onChange={(e) => handleInputChange('invoiceStatus', e.target.value)}
                                options={invoiceStatusOptions}
                            />
                        </div>
                        <div className="px-3">
                            <Button
                                color='primary'
                                variant="contained"
                                ripple="true"
                                onClick={handleSubmit}
                            >
                                Créer la Facture
                            </Button>
                        </div>
                    </>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>


            {/* Modal pour les reçus */}
            <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
                <Card className='bg-gray-200 w-full'>
                    <CardContent>
                        <Typography variant='h5' color='blue-gray' className="mb-4">
                            Reçus de caisse payés
                        </Typography>
                        {paidReceipts.length > 0 ? (
                            <div className="flex flex-col space-y-4">
                                {paidReceipts.map(receipt => (
                                    <Box key={receipt.id} display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography>{receipt.num_document}</Typography>
                                        <Checkbox
                                            checked={selectedReceipts.includes(receipt.id)}
                                            onChange={() => handleReceiptSelection(receipt.id)}
                                        />
                                    </Box>
                                ))}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={createInvoiceFromReceipts}
                                >
                                    Créer la Facture
                                </Button>
                            </div>
                        ) : (
                            <Typography>Aucun reçu de caisse payé trouvé</Typography>
                        )}
                    </CardContent>
                </Card>
            </Modal>

            {/* Modal pour créer un nouveau client */}
            <Modal isOpen={isNewClientModalOpen} closeModal={handleCloseNewClientModal}>
                <Card className='bg-gray-200 w-full'>
                    <CardContent>
                        <Typography variant='h5' color='blue-gray' className="mb-4">
                            Créer un Nouveau client
                        </Typography>
                        {clientTypeMetadata && Object.entries(clientTypeMetadata.properties).map(([key, property]) =>
                            renderInputField(key, property)
                        )}
                        <Button
                            color='primary'
                            variant="contained"
                            ripple="true"
                            className='w-full md:w-auto mt-4'
                            onClick={handleCreateClient}
                        >
                            Créer
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </Card>
    );
};

export default InvoiceForm;
